.App {
  text-align: center;
}
.header-employee-expense{
  margin-top: 42px;
  padding-top: 45px;
  background-image: url("./assets/img/bgEmployeeExpense.svg");
  background-repeat: no-repeat;
  background-position: center;
  height: 145px;
  width: 100%;
}
.header-employee-expense h5{
  margin-top:0 ;
  margin-left: 100px;
  font-family: Quicksand;
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 30px;
color: #12344D;
}
.background {
  background-color: #fcfcfc;
}


.select-navbar {
  margin-left: 20px;
  height: 30px;
  width: 228px;
  border-radius: 4px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;

  color: #012b6f;
}
.select-navbar:hover,
.select-navbar:focus,
.select-navbar:active {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}
.navbar-vendor {
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #12344d;
}
.info-account-vendor {
  width: max-content;
  height: 32px;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #12344d;
  text-align: center;
  align-self: center;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 20px;
}
/* form PP */
.label-search-form-pp {
  font-family: Quicksand;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #12344d;
  margin-right: 40px;
  margin-top: 10px;
}
.select-search-form-pp-vendor {
  width: 432px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 2px;
  margin-right: 70px;
}
.select-search-form-pp-prioriy {
  width: 197px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 2px;
}
.input-date-search-form-pp {
  width: 197px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 2px;
}
.select-data-priority {
  width: 88px;
  height: 28px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
}
/* close form pp */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.dialog-changePass{
  padding: 40px 20px;
}

.label-dialog {
  display: flex;
  flex-direction: column !important;
}
.label-dialog label{
  margin-bottom: 28px;
  font-family: 'Quicksand';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 18px;
color: #000000;

}
.label-dialog input{
  padding-left: 15px;
  width: 100%;
height: 34px;
margin-bottom: 10px;
background: #FFFFFF;
border: 1px solid #E0E0E0;
box-sizing: border-box;
border-radius: 4px;
}
.label-dialog input:focus,
.label-dialog input:hover,
.label-dialog input:active
{
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.submit-button{
  width: 130px;
  height: 40px;
  background-color:#33A1FD;
border: 1px solid #33A1FD;
box-sizing: border-box;
border-radius: 4px;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
}
.list-error{
  color:#CB605A;
  font-family: 'Quicksand';
font-style: normal;
font-weight: 600;
font-size: 14px;
padding-left: 15px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
