.container-vendor {
  padding-top: 70px;
  width: 100%;
}
.header-vendor {
  padding: 30px;
  height: max-content;
  background: #f8f8f8;
}
.header-vendor-invoice{
  position: fixed;
  background: #f8f8f8;
  padding: 30px;
  width: 94vw;
  z-index: 999;
}
.header-vendor h6,
.header-vendor-invoice h6 {
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 35px;
  margin-top: 0;
  color: #12344d;
}
.header-vendor p,
.header-vendor-invoice p {
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-top: -40px;
  color: #12344d;
}
.header-vendor span,
.header-vendor-invoice span{
  font-family: 'Quicksand';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 15px;
text-align: right;
color: #12344D;
}

.header-vendor h2,
.header-vendor-invoice h2{
  font-family: 'Quicksand';
font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 35px;
/* identical to box height */

text-align: right;
text-decoration-line: underline;

color: #12344D;
}

.content-list-ipms{
  padding: 0;
}

.content-list-po,
.content-edit-profile {
  padding: 30px;
}
/* detail po */
.detail-po {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px;
  background: #ffffff;
  border: 1px solid #c4d2ea;
  box-sizing: border-box;
}
.detail-po h5 {
  margin: 0;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 35px;
  color: #12344d;
}
.square-table {
  padding-top: 5px;
  width: 100%;
  height: max-content;
  border: 1px solid rgba(196, 210, 234, 0.6);
  box-sizing: border-box;
  border-radius: 8px;
}
.detail-info label {
  font-family: Quicksand;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #12344d;
}
.detail-info input {
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px;
  width: 100%;
  height: 42px;
  background: #f2f2f2;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 2px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
}
.download-Quotation{
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  height: 42px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 2px;
}
.download-Quotation p{
  margin-left: 10px !important;
  font-family: 'Quicksand';
font-style: normal;
font-weight: 500;
font-size: 14px !important;
line-height: 18px;
color: #33A1FD !important;
cursor: pointer;
text-decoration: underline;
}
/* end detail po */
/* start edit profile */
.square {
  padding: 30px;
  width: 100%;
  height: max-content;
  border: 1px solid rgba(196, 210, 234, 0.6);
  box-sizing: border-box;
  border-radius: 8px;
}
.square p,
.square-table h2{
  font-family: 'Quicksand';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 25px;
color: #7C8388;
margin: 6px 45px;
}
.square h3 {
  margin-top: 0;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: #12344d;
}
.square h5,
.square-table h5{
  font-family: 'Quicksand';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 41px;
margin: 0;
color: #12344D;
}
.square .label-input {
  display: flex;
  flex-direction: column;
}
.square .label-input label {
  /* margin-right: 40px; */
  font-family: Quicksand;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #12344d;
}
.square .label-input input,
.square .label-input textarea {
  padding: 10px;
  width: 432px;
  height: 38px;
  margin-bottom: 25px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 2px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  color: #7c8388;
}
.square .label-input select {
  padding: 10px;

  width: 432px;
  margin-bottom: 25px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 2px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  color: #7c8388;
}
.detail-info input:hover,
.detail-info input:focus,
.detail-info input:active,
.square .label-input input:hover,
.square .label-input input:focus,
.square .label-input input:active,
.square .label-input select:hover,
.square .label-input select:focus,
.square .label-input select:active,
.square .label-input textarea:hover,
.square .label-input textarea:focus,
.square .label-input textarea:active,
.select-create-invoice:hover,
.select-create-invoice:focus,
.select-create-invoice:active,
.input-BilledQty:focus,
.input-BilledQty:hover,
.input-BilledQty:active,
.input-amountType:focus,
.input-amountType:hover,
.input-amountType:active,
.input-instalment:focus,
.input-instalment:hover,
.input-instalment:active,
.input-document-no:focus,
.input-document-no:hover,
.input-document-no:active,
.select-profile-bank:focus,
.select-profile-bank:hover,
.select-profile-bank:active,
.input-profile-bank:focus,
.input-profile-bank:hover,
.input-profile-bank:active,
.text-area-reject-po:focus,
.text-area-reject-po:active,
.text-area-reject-po:hover
{
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.text-area-reject-po{
  width: 100%;
  height: 200px;
  padding: 10px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 2px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
}
/* end edit profile */

/* start Invoice Submission */
.select-create-invoice{
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding:10px;
  width: 100%;
height: 42px;
background: #FFFFFF;
border: 1px solid #E0E0E0;
box-sizing: border-box;
border-radius: 2px;
font-family: 'Quicksand';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 18px;
color: #12344D;
}

.input-BilledQty{
  width: 100%;
height: 28px;
background: #FFFFFF;
border: 1px solid #E0E0E0;
box-sizing: border-box;
border-radius: 4px;
margin-left: 10px;
font-family: 'Quicksand';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 41px;
/* or 293% */
text-align: center;

color: #12344D;
}
.input-amountType{
  width: 100%;
height: 28px;
background: #FFFFFF;
/* border: 1px solid #E0E0E0; */
border: none;
box-sizing: border-box;
border-radius: 4px;
/* margin-left: 10px; */
font-family: 'Quicksand';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 41px;
/* padding-left: 10px; */

color: #12344D;
}

.input-amountType-warning{
  width: 100%;
height: 28px;
background: #FFFFFF;
border: 1px solid #CB605A;
box-sizing: border-box;
border-radius: 4px;
/* margin-left: 10px; */
font-family: 'Quicksand';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 41px;
/* padding-left: 10px; */

color: #12344D;
}


.input-instalment{
  padding:10px ;
  width: 180px;
height: 28px;
background: #FFFFFF;
border: 1px solid #E0E0E0;
box-sizing: border-box;
border-radius: 4px;
font-family: 'Quicksand';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 41px;
/* or 293% */
text-align: center;

color: #12344D;
}
.input-document-no{
  padding:10px ;
  width: 180px;
height: 28px;
background: #FFFFFF;
border: 1px solid #E0E0E0;
box-sizing: border-box;
border-radius: 4px;
font-family: 'Quicksand';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 41px;
/* or 293% */
text-align: center;

color: #12344D;
}
.select-item-Type{
  background: #FFFFFF;
border: 1px solid #E0E0E0;
box-sizing: border-box;
border-radius: 4px;
font-family: 'Quicksand';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 41px;
color: #12344D;
}

.text-upload-file{
  font-family: 'Quicksand';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 41px;
/* or 293% */

text-decoration-line: underline !important;
cursor: pointer;
color: #012B6F;

}

.uploadFile-name{
  font-family: 'Quicksand';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 41px;
/* or 293% */

text-decoration-line: underline;

color: #33A1FD;
cursor: pointer;
}
.delete-upload-text{
  margin-left: 40px;
  font-family: 'Quicksand';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 41px;
/* or 293% */

text-decoration-line: underline;

color: #CB605A;
cursor: pointer;
}


.select-profile-bank{
  width: 75%;
height: 28px;
  background: #FFFFFF;
border: 1px solid #E0E0E0;
box-sizing: border-box;
border-radius: 4px;
}

.input-profile-bank{
  width: 75%;
height: 28px;
padding-left: 10px;
background: #FFFFFF;
border: 1px solid #E0E0E0;
box-sizing: border-box;
border-radius: 4px;
}

.btn-for-vendor-outlined{
  margin-left: 20px;
  padding-top: 5px;
  width: 150px;
height: 40px;
background: #FFFFFF;
border: 1px solid #E0E0E0;
box-sizing: border-box;
border-radius: 4px;
text-align: center;
align-items: center;
}
.btn-for-vendor-loading-submit{
  margin-left: 20px;
  padding-top: 5px;
  width: 150px;
height: 40px;
background: #33A1FD;
border: 1px solid #33A1FD;
box-sizing: border-box;
border-radius: 4px;
text-align: center;
align-items: center;
}
.mandatory-doc{
  position: relative;
}
.mandatory-doc::after{
  font-family: fontAwesome;
  content: "\f005";
  font-size: 8px;
  color:#CB605A;
  position: absolute;
  margin-left: 5px;
}




