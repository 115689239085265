/* general for auth */
.bg-auth-portal {
  background-image: url("../../assets/img/Bg_auth_left.png");
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
}
.bg-circle-top-left {
  position: absolute;
  top: 0;
  background: rgba(255, 255, 255, 0.3);
  width: 150px;
  height: 150px;
  border-bottom-right-radius: 100%;
}

.bg-circle-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.3);
  width: 200px;
  height: 300px;
  border-top-left-radius: 100vw;
  border-bottom-left-radius: 100vw;
}
.side-auth {
  /* position: relative; */
  height: 100vh;
  padding-left: 60px;
  padding-right: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title-auth-right {
  display: flex;
}
.title-auth-right img {
  margin-top: 18px;
  margin-right: 10px;
}
.title-auth-right p {
  font-family: Gotham Rounded;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.05em;
  color: #12344d;
}
.side-auth-register {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 60px;
  padding-right: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.side-auth-register h4 {
  align-self: flex-start;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #12344d;
  margin-bottom: 45px;
}
.side-auth-register label {
  align-self: flex-start;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #828282;
  margin-bottom: 10px;
}
.side-auth p {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #333333;
}

.input-auth {
  width: 100%;
  height: 42px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  background: #f2f2f2;
  border-radius: 8px;
  border: none;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #828282;
}

.select-auth {
  width: 110% !important;
  height: 42px;
  padding-left: 20px;
  margin-bottom: 25px;
  background: #f2f2f2;
  border-radius: 8px;
  border: none;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #828282;
}
textarea {
  resize: none;
}
.side-auth input:hover,
.side-auth input:focus,
.side-auth input:active,
.side-auth select:hover,
.side-auth select:focus,
.side-auth select:active,
.side-auth textarea:hover,
.side-auth textarea:focus,
.side-auth textarea:active,
.side-auth-register input:hover,
.side-auth-register input:focus,
.side-auth-register input:active,
.side-auth-register select:hover,
.side-auth-register select:focus,
.side-auth-register select:active,
.side-auth-register textarea:hover,
.side-auth-register textarea:focus,
.side-auth-register textarea:active {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}
.label-as {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.label-as p {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #000000;
}
.label-as-vendor {
  width: 100px;
  height: 36px;
  margin-left: 20px;
  background: #ffcd08;
  border-radius: 30px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #12344d;
}

.tab-link {
  display: flex;
  margin-top: 20px;
}
.tab-link .active {
  width: 50%;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #000000;
  padding: 5px;
  border-bottom: 1px solid #12344d;
  text-decoration: none;
}
.tab-link .inactive {
  width: 50%;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  border-bottom: 1px solid #e0e0e0;
  padding: 5px;
  text-align: center;
  color: #bdbdbd;
  text-decoration: none;
}
.tab-number {
  display: flex;
  margin-top: 50px;
}

label.filebutton {
  overflow: hidden;
  position: relative;
  width: 360px;
  height: 32px;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  background: #f2f2f2;
  border-radius: 8px;
  border: none;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #828282;
}

label span input {
  z-index: 999;
  line-height: 0;
  /* font-size: 50px; */
  position: absolute;
  top: -2px;
  left: -700px;
  opacity: 0;
  filter: alpha(opacity = 0);
  -ms-filter: "alpha(opacity=0)";
  cursor: pointer;
  _cursor: hand;
  margin: 0;
  padding: 0;
}
label span img {
  position: absolute;
  right: 20px;
}
.file-name {
  position: absolute;
  right: 30px;
  top: 15px;
}
.dynamic-register {
  justify-content: center;
}

.btn-loading{
  margin-top: 20px;
  margin-right: 20px;
  width: 149px;
  height: 50px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  background: #12344d;
  color: #ffffff;
  border: 1px solid #12344d;
  box-sizing: border-box;
  border-radius: 25px;
  cursor: pointer;
}
.loaderDownload{
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #ffcd08;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}